import { Content } from "@/models/content";
import { ContentManager } from "@/services/contentManager";

export const day7: Content[] = [
    {
        id: "5c67b15f-04a3-423d-953c-93b6705da2dc",
        text: [
            "Hi <nick name>! Welcome back to wMammogram project! This is the last day!"
        ],
        textStyle: [
            ["text-h5", "text-center"]
        ],
        pinkie: {
            src: require("@/assets/pinkie/hello.png"),
            animation: "pinkie-hello",
            left: true
        }
    },
    {
        id: "69c8cb80-cd79-418a-ad1a-682c8922247b",
        text: [
            "Today, we will review the key points that we’ve talked about so far with quizzes."
        ]
    },
    {
        id: "039e3e48-ea15-4492-aa6a-5fb67ca7e04a",
        question: {
            question: "What is the best screening test to detect an impalpable mass in breast tissue (that may or may not be breast cancer)?",
            answers: [
                "Breast Self-exam",
                "Clinical Breast exam",
                "Mammogram"
            ],
            correct: 2,
            text: [
                [
                    "Breast self-exam is somewhat helpful but has a limitation that it cannot detect an impalpable mass. Finding an impalpable mass is a strength of a mammogram!!"
                ],
                [
                    "Clinical breast exam is somewhat helpful but has a limitation that it cannot detect an impalpable mass.",
                    "Finding an impalpable mass is a strength of a mammogram!!"
                ],
                [
                    "Yeah!!! You’re right!!",
                    "Finding an impalpable mass is a strength of a mammogram!!"
                ]
            ]
        }
    },
    {
        id: "3938a114-37ff-435e-88d8-51520ccf4a73",
        question: {
            question: "When should women start to get a regular mammogram?",
            answers: [
                "40 years old",
                "50 years old",
                "60 years old"
            ],
            correct: 0,
            text: [
                [
                    "You got it!!!",
                    "It may vary according to each individual’s risk factors that she has.",
                    "However, it is generally recommended that women aged 40 year old or over should get regular mammograms!"
                ],
                [
                    "It may vary according to each individual’s risk factors that she has.",
                    "However, it is generally recommended that women aged 40 year old or over should get regular mammograms!"
                ],
                [
                    "It may vary according to each individual’s risk factors that she has.",
                    "However, it is generally recommended that women aged 40 year old or over should get regular mammograms!"
                ]
            ]
        }
    },
    {
        id: "0acded94-eb10-4b09-bcf8-a4297bd67962",
        question: {
            question: "Would getting a mammogram every 3 years be enough. Is this true or false?",
            answers: [
                "True",
                "False"
            ],
            correct: 1,
            text: [
                [
                    "This statement is false.",
                    "Getting mammograms every 1 or 2 years is recommended!!!"
                ],
                [
                    "You’re right!!! Getting mammograms every 1 or 2 years is recommended!!!"
                ]
            ]
        }
    },
    {
        id: "11d7180c-1e8e-4fa6-9751-1a0b242d428d",
        question: {
            question: "You just have completed the 7-day wMammogram project. Do you think you should receive a mammogram soon?",
            answers: [
                "Within next month",
                "Within next three months",
                "Within next six months",
                "No plan yet"
            ],
            correct: 0,
            text: [
                [
                    "Awesome!!",
                    "Do you need any help to make an appointment for a mammogram?",
                    "Please feel free to contact us! We’d love to help you!!!"
                ],
                [
                    "Awesome!!",
                    "Do you need any help to make an appointment for a mammogram?",
                    "Please feel free to contact us! We’d love to help you!!!"
                ],
                [
                    "Awesome!!",
                    "Do you need any help to make an appointment for a mammogram?",
                    "Please feel free to contact us! We’d love to help you!!!"
                ],
                [
                    "All right!",
                    "By any chance, if you need any help regarding getting a mammogram, please feel free to contact us!",
                    "We’d love to help you!"
                ]
            ]
        }
    },
    {
        id: "bbda6c16-4a9d-4150-8ab2-a3da974a8526",
        text: [
            "You successfully completed the 7 day wMammogram project!",
            "We really appreciate your participation and hope it was fun and helpful for you!",
            "Our health navigator will call you for your feedback.",
            "You will also receive a monthly notification that asks if you complete a mammogram after completing the wMammogram project.",
            "In the meanwhile, if you have any questions or need any help, feel free to contact us!"
        ]
    }
];

export const manager = new ContentManager(day7);

export default day7;