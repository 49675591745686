import { Content } from "@/models/content";

export class ContentManager {
    private content: Content[];

    private current: Content | undefined;

    public get length() {
        return this.content.length;
    }

    public get progress() {
        return this.currentIndex < 0 ? 0 : (this.currentIndex / this.length);
    }

    public get last() {
        return this.currentIndex === this.length - 1;
    }

    public get first() {
        return this.currentIndex === 0;
    }

    public get firstId() {
        return this.content[0].id;
    }

    public get Current() {
        return this.current;
    }

    constructor(content: Content[]) {
        if (content.length === 0)
            throw new Error("Content is empty!");

        this.content = content;
        this.current = content[0];
    }

    public next() {
        if (this.currentIndex < 0)
            return;
        this.current = this.content[this.currentIndex + 1];
        return this.current;
    }

    public prev() {
        if (this.currentIndex <= 0)
            return;
        this.current = this.content[this.currentIndex - 1];
        return this.current;
    }

    public set(id: string) {
        const c = this.content.find(x => x.id === id);
        if (c === undefined)
            throw new Error(`Cannot find content with id ${id}`);
        this.current = c;
    }

    public get currentIndex() {
        if (!this.current)
            return -1;
        return this.content.indexOf(this.current);
    }

    *[Symbol.iterator]() {
        yield* this.content;
    }

    public values() {
        return Array.from(this.content);
    }

    public isNewMax(max: string) {
        const maxIdx = this.content.findIndex(c => c.id === max);
        if (maxIdx === -1)
            throw new Error(`Cannot find content with id ${max}`);
        return this.currentIndex > maxIdx;
    }

    public isBack(max: string) {
        const maxIdx = this.content.findIndex(c => c.id === max);
        if (maxIdx === -1)
            throw new Error(`Cannot find content with id ${max}`);
        return this.currentIndex < maxIdx;
    }
}